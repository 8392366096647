import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import switchIcon from "../../assets/switch-icon.svg"
import MultipleRgbToLab from "./MultipleRgbToLab";
import MultipleLabToRgb from "./MultipleLabToRgb";
import MultipleLuvToRgb from "./MultipleLuvToRgb";
import MultipleRgbToRgb from "./MultipleRgbToRgb";
import MultipleRgbToLuv from "./MultipleRgbToLuv";
import MultipleXYyToRgb from "./MultipleXYyToRgb ";


const MultipleMenu = () => {
  const { t, i18n } = useTranslation();
  const [currentConversion, setCurrentConversion] = useState('labToRgb');
  const navigate = useNavigate();
  const currentLanguage = i18n.language;

  const handleConversionChange = (conversionType) => {
    setCurrentConversion(conversionType);
  };

  const handleRedirectToConverter = () => {
    const urlPath = currentLanguage === 'pl' ? '/konwerter' : '/converter';
    navigate(urlPath);
  };

  return (
    <>
      <div className="norm">{t('title')}<br />{t('title2')}<br />{t('norm')}</div>

      <div className="konwerter-menu">
        <button className={`konwerter-menu-button ${currentConversion === 'labToRgb' ? 'konwerter-menu-button-selected' : ''}`} onClick={() => handleConversionChange('labToRgb')}> L*a*b* {t('to')} RGB </button>
        <button className={`konwerter-menu-button ${currentConversion === 'rgbToLab' ? 'konwerter-menu-button-selected' : ''}`} onClick={() => handleConversionChange('rgbToLab')}> RGB {t('to')} L*a*b* </button>
        <button className={`konwerter-menu-button ${currentConversion === 'luvToRgb' ? 'konwerter-menu-button-selected' : ''}`} onClick={() => handleConversionChange('luvToRgb')}> L*u*v* {t('to')} RGB  </button>
        <button className={`konwerter-menu-button ${currentConversion === 'rgbToLuv' ? 'konwerter-menu-button-selected' : ''}`} onClick={() => handleConversionChange('rgbToLuv')}> RGB {t('to')} L*u*v* </button>
        <button className={`konwerter-menu-button ${currentConversion === 'rgbToRgb' ? 'konwerter-menu-button-selected' : ''}`} onClick={() => handleConversionChange('rgbToRgb')}> RGB {t('to')} RGB </button>
        <button className={`konwerter-menu-button ${currentConversion === 'xyyToRgb' ? 'konwerter-menu-button-selected' : ''}`} onClick={() => handleConversionChange('xyyToRgb')}> xyY {t('to')} RGB </button>
        <button className="switch-button" onClick={handleRedirectToConverter} >
          <img src={switchIcon} className="button-icon" alt="button" />
          {t("switchToConverter")}
        </button>
      </div>

      {currentConversion === 'labToRgb' ? <MultipleLabToRgb /> :
        currentConversion === 'rgbToLab' ? <MultipleRgbToLab /> :
          currentConversion === 'luvToRgb' ? <MultipleLuvToRgb /> :
            currentConversion === 'rgbToLuv' ? <MultipleRgbToLuv /> :
              currentConversion === 'rgbToRgb' ? <MultipleRgbToRgb /> :
                currentConversion === 'xyyToRgb' ? <MultipleXYyToRgb /> : null}
    </>

  );
};
export default MultipleMenu
