import EOTF from "./EOTF";

const RGBtoXYZ = (R, G, B, RgbWorkingSpace) => {   

    const gammaFix = EOTF(R, G, B, RgbWorkingSpace.value);
    R = gammaFix.R;
    G = gammaFix.G;
    B = gammaFix.B;
    
    R > 1 ? R = 1 : R = R;
    R < 0 ? R = 0 : R = R;

    G > 1 ? G = 1 : G = G;
    G < 0 ? G = 0 : G = G;
  
    B > 1 ? B = 1 : B = B;
    B < 0 ? B = 0 : B = B;


    let X, Y, Z
    switch (RgbWorkingSpace.value) {
        case 'sRGB':
            X = 0.412369 * R + 0.357600 * G + 0.180431 * B;
            Y = 0.212628 * R + 0.715200 * G + 0.072172 * B;
            Z = 0.019330 * R + 0.119200 * G + 0.950270 * B;
            break;
        case 'Rec709full':
            X = 0.412369 * R + 0.357600 * G + 0.180431 * B;
            Y = 0.212628 * R + 0.715200 * G + 0.072172 * B;
            Z = 0.019330 * R + 0.119200 * G + 0.950270 * B;
            break;
        case 'Rec709normative':
            X = 0.412369 * R + 0.357600 * G + 0.180431 * B;
            Y = 0.212628 * R + 0.715200 * G + 0.072172 * B;
            Z = 0.019330 * R + 0.119200 * G + 0.950270 * B;
            break;
        case 'Adobe RGB (1998)':
            X = 0.576654 * R + 0.185566 * G + 0.188179 * B;
            Y = 0.297337 * R + 0.627391 * G + 0.075272 * B;
            Z = 0.027031 * R + 0.070692 * G + 0.991077 * B;
            break;
        case 'Display P3':
            X = 0.4866 * R + 0.2657 * G + 0.1982 * B;
            Y = 0.2290 * R + 0.6918 * G + 0.0793 * B;
            Z = 0.0000 * R + 0.0451 * G + 1.0437 * B;
            break;
        case 'ECIv2':
            X = 0.6502 * R + 0.1780 * G + 0.1361 * B;
            Y = 0.3201 * R + 0.6019 * G + 0.0780 * B;
            Z = 0.0000 * R + 0.0680 * G + 0.7571 * B;
            break;
        case 'ProPhoto':
            X = 0.7977 * R + 0.1352 * G + 0.0313 * B;
            Y = 0.2880 * R + 0.7119 * G + 0.0001 * B;
            Z = 0.0000 * R + 0.0000 * G + 0.8251 * B;
            break;
        default:
            console.error('Unsupported RGB profile');
            return;
    }

    return { X, Y, Z};
};

export default RGBtoXYZ;
