const XyzToRGBMatrix = (X, Y, Z, RgbWorkingSpace) => {
    let R, G, B;
    switch (RgbWorkingSpace) {
        case 'ECIv2': //https://www.color.org/ecirgb.pdf
            R = 1.782574 * X - 0.496652 * Y - 0.269186 * Z;
            G = -0.959075 * X + 1.948093 * Y - 0.028304 * Z;
            B = 0.086144 * X - 0.174977 * Y + 1.323376 * Z;
            break;
        case 'ProPhoto': //https://www.color.org/ecirgb.pdf
            R = 1.3460 * X - 0.2556 * Y - 0.0511 * Z;
            G = -0.5446 * X + 1.5082 * Y + 0.0205 * Z;
            B = 0 * X + 0 * Y + 1.2120 * Z;
            break;
        case 'sRGB':
            R = 3.241141 * X - 1.537464 * Y - 0.498637 * Z;
            G = -0.969201 * X + 1.875886 * Y + 0.041553 * Z;
            B = 0.055645 * X - 0.204033 * Y + 1.057263 * Z;
            break;
        case 'Rec709full':
            R = 3.241141 * X - 1.537464 * Y - 0.498637 * Z;
            G = -0.969201 * X + 1.875886 * Y + 0.041553 * Z;
            B = 0.055645 * X - 0.204033 * Y + 1.057263 * Z;
            break;
        case 'Rec709normative':
            R = 3.241141 * X - 1.537464 * Y - 0.498637 * Z;
            G = -0.969201 * X + 1.875886 * Y + 0.041553 * Z;
            B = 0.055645 * X - 0.204033 * Y + 1.057263 * Z;
            break;
        case 'Adobe RGB (1998)':
            R = 2.041640 * X - 0.565021 * Y - 0.344740 * Z;
            G = -0.969201 * X + 1.875886 * Y + 0.041553 * Z;
            B = 0.013448 * X - 0.118393 * Y + 1.015441 * Z;
            break;
        case 'Display P3':
            R = 2.493586 * X - 0.931417 * Y - 0.402725 * Z;
            G = -0.829456 * X + 1.762593 * Y + 0.023624 * Z;
            B = 0.035855 * X - 0.076191 * Y + 0.957123 * Z;
            break;
        default:
            console.error('Unsupported RGB profile');
            return;
    }
    return { R, G, B };
};

export default XyzToRGBMatrix;
