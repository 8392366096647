import i18n from 'i18next';
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import backgroundImg from "./assets/background-image.png";
import backgroundImgDark from "./assets/background-image-dark.png";
import bulletpoint from "./assets/bulletpoint.svg";
import taskIcon1 from "./assets/task-icon-1.svg";
import taskIcon2 from "./assets/task-icon-2.svg";
import goal1pic from "./goals/goal1.svg";
import goal2pic from "./goals/goal2.svg";
import goal3pic from "./goals/goal3.svg";
import goal3picDark from "./goals/goal3-dark.svg";
import goal4pic from "./goals/goal4.svg";
import goal4picDark from "./goals/goal4-dark.svg";
import diagnosisLogo from "./logos/diagnosis_logo.svg";
import diagonalLogo from "./logos/diagonal_logo.svg";
import diagramLogo from "./logos/diagram_logo.svg";
import Footer from "./welcome-footer";
import Header from "./welcome-header";
import "./welcome-page.css";

import archiwaPanstwowe from "./partner_logos/AP_logo.png";
import archiwumGlowneAktDawnych from "./partner_logos/Archiwum_Głowne_Akt_Dawnych_logo_gray.jpg";
import archiwumPanstwowePoznan from "./partner_logos/archiwum_panstwowe_w_poznaniu.png";
import bibliotekaJagiellonska from "./partner_logos/biblioteka_jagiellonska.png";
import biblioteka_narodowa from "./partner_logos/Biblioteka_Narodowa.png";
import bibliotekaUam from "./partner_logos/biblioteka_uam.png";
import bibliotekaUniwersytetuWroclawskiego from "./partner_logos/biblioteka_uniwersytecka_wroclaw.png";
import galeriaSztukiWozownia from "./partner_logos/galeria_sztuki_wozownia.png";
import muzeumHistoryczneKrakow from "./partner_logos/muzeum_krakowa.png";
import muzeumNarodoweGdansk from "./partner_logos/muzeum_narodowe_w_gdansku.png";
import muzeumPalacuKrolaJana from "./partner_logos/muzeum_palacu_krola_jana_3_w_wilanowie.png";
import narodoweArchiwumCyfrowe from "./partner_logos/NAC_logo.svg";
import narodowyInstytutMuzeow from "./partner_logos/NIM_logo.png";
import politechnikaWarszawska from "./partner_logos/politechnika_warszawska.png";
import uniwersytetPapieski from "./partner_logos/uniwersytet_papieski_jana_pawla_2.png";
import ossolineum from "./partner_logos/zaklad_narodowy_ossolineum.png";
import zamekKrolewskiNaWawelu from "./partner_logos/zamek_krolewski_na_wawelu.png";
import '@fontsource/barlow/700.css';
import { useTheme } from '../ThemeContext';

const WelcomePage = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const currentLanguage = i18n.language;

  useEffect(() => {
    document.title = "Diag";
  }, []);

  const goalsData = [
    { img: goal1pic, desc: t("goal1") },
    { img: goal2pic, desc: t("goal2") },
    { img: theme === 'dark' ? goal3picDark : goal3pic, desc: t("goal3") },
    { img: theme === 'dark' ? goal4picDark : goal4pic, desc: t("goal4") },
  ];

  const createGoalRow = (goals, isEvenRow) => {
    return (
      <div className="goals-row">
        {goals.map((goal, index) => (
          <React.Fragment key={index}>
            {isEvenRow ? (
              <>
                <div className="goal-item goal-img reverse-bg">
                  <img src={goal.img} alt={`Cel ${index + 1}`} />
                </div><div className="goal-item goal-desc reverse-bg">
                  <p>{goal.desc}</p>
                </div>
              </>
            ) : (
              <>
                <div className="goal-item goal-img">
                  <img src={goal.img} alt={`Cel ${index + 1}`} />
                </div>
                <div className="goal-item goal-desc">
                  <p>{goal.desc}</p>
                </div>
              </>
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };
  const generateGoals = () => {
    const rowsData = [
      [goalsData[0], goalsData[1]],
      [goalsData[2], goalsData[3]],
    ];

    return rowsData.map((goals, rowIndex) => {
      const isEvenRow = rowIndex % 2 === 1;
      if (rowIndex === rowsData.length - 1 && goals.length === 1) {
        //  last row case
        return (
          <div key={rowIndex} className="goals-row last-row">
            <div className="goal-item goal-img">
              <img src={goals[0].img} alt={`Cel ${rowIndex * 2 + 1}`} />
            </div>
            <div className="goal-item goal-desc">
              <p>{goals[0].desc}</p>
            </div>
            <div className="goal-item goal-img empty"></div>
            <div className="goal-item goal-desc empty"></div>
          </div>
        );
      }
      return <React.Fragment key={rowIndex}>{createGoalRow(goals, isEvenRow)}</React.Fragment>;
    });
  };

  return (
    <>
      <div className="welcome-page-wrapper">
        <Header />
        <div className="welcome-container">

          <div className="poem-container">
            <img 
              src={backgroundImg}
              className={`background-img ${theme === 'dark' ? 'hidden' : ''}`}
              alt="background image" 
            />
            <img 
              src={backgroundImgDark}
              className={`background-img ${theme === 'light' ? 'hidden'  : ''}`}
              alt="background image" 
            />
            <div className="poem">
              “Na niebie wydmuchał wiatr zimne i martwe kolory,<br />
              grynszpanowe, żółte i liliowe smugi,<br />
              dalekie sklepienie i arkady swego labiryntu”<br />
              <span className="shultz">Bruno Schulz, Sklepy cynamonowe</span>
            </div>
          </div>


          <div className="diag-logo-bg">
            <div className="diag-logo">
              <div className="diag-logo-container">
                <img src={diagramLogo} alt="" className="diag-logo-img-img" />
                <div className="diag-logo-text">{t("diag-logo-knowledge-base")}</div>
              </div>
              <a className="diag-logo-container" href="https://haematoxylum.man.poznan.pl/" target="_blank" rel="noreferrer">
                <img src={diagnosisLogo} alt="" className="diag-logo-img-img" />
                <div className="diag-logo-text">{t("diag-logo-analysis")}</div>
              </a>
              <div className="diag-logo-container">
                <img src={diagonalLogo} alt="" className="diag-logo-img-img" />
                <div className="diag-logo-text">{t("diag-logo-targets")}</div>
              </div>
            </div>
          </div>


          <h2 className="diag-h2 diag-h2-1">
            {t("diag-vision")}
          </h2>
          <div className="vision-container">
            <div className="vision-text-container">
              <p className="welcome-text" style={{ margin: 0 }}>{t("diag-vision-text-1")}</p>
              <p className="welcome-text" style={{ margin: 0, paddingTop: "20px" }}>{t("diag-vision-text-2")}</p>
            </div>

            <div className="diag-task-container">
              <div className="diag-task">
                <img src={taskIcon1} style={{ marginLeft: "30px" }} alt="" />
                <div style={{ width: "300px" }}>{t("diag-task-1")}</div>
              </div>
              <div className="diag-task">
                <img src={taskIcon2} style={{ marginLeft: "30px" }} alt="" />
                <div style={{ width: "300px" }}>{t("diag-task-2")}</div>
              </div>
            </div>
          </div>


          <h2 className="diag-h2 diag-h2-2">{t("goals")}</h2>
          <div id="goals-container">{generateGoals()}</div>


          <h2 className="diag-h2 diag-h2-3">{t("project-tools")}</h2>
          <div className="diag-tools">
            <div className="diag-tools-container">
              <img src={bulletpoint} alt="" />
              <a href="https://haematoxylum.man.poznan.pl/" className="diag-tools-link" target="_blank" rel="noreferrer">
                {t("project-tools-app")}
              </a>
            </div>
            <div className="diag-tools-container">
              <img src={bulletpoint} alt="" />
              <a href={currentLanguage === 'pl' ? "/konwerter" : "/converter"} className="diag-tools-link">
                {t("project-tools-converter")}
              </a>
            </div>
            <div className="diag-tools-container">
              <img src={bulletpoint} alt="" />
              <a href="https://diag.pcss.pl/biuletyn/" className="diag-tools-link">
                {t("Biuletyn")}
              </a>
            </div>
          </div>

          <h2 className="diag-h2 diag-h2-3">{t("partners-header")}</h2>
          <div className="partners-logos-bg">
            <div className='partners-logos'>
              <a target="_blank" rel="noopener noreferrer" href='https://wozownia.pl/'><img src={galeriaSztukiWozownia} alt={"Galeria sztuki Wozownia"} width={105}></img></a>
              <a target="_blank" rel="noopener noreferrer" href='https://www.bn.org.pl/'><img src={biblioteka_narodowa} alt={"Biblioteka Narodowa"} width={160}></img></a>
              <a target="_blank" rel="noopener noreferrer" href='https://bj.uj.edu.pl/'><img src={bibliotekaJagiellonska} alt={"Biblioteka Jagiellońska"} width={129}></img></a>
              <a target="_blank" rel="noopener noreferrer" href='https://www.ossolineum.pl/'><img src={ossolineum} alt={"Zakład Narodowy im. Ossolińskich"} width={37}></img></a>
              <a target="_blank" rel="noopener noreferrer" href='https://www.bu.uni.wroc.pl/'><img src={bibliotekaUniwersytetuWroclawskiego} alt={"Biblioteka Uniwersytecka we Wrocławiu"} width={91}></img></a>
              <a target="_blank" rel="noopener noreferrer" href='https://lib.amu.edu.pl/'><img src={bibliotekaUam} alt={"Biblioteka Uniwersytecka w Poznaniu"} width={119}></img></a>
              <a target="_blank" rel="noopener noreferrer" href='https://archiwa.gov.pl/'><img src={archiwaPanstwowe} alt={"Archiwa Państwowe"} width={118}></img></a>
              <a target="_blank" rel="noopener noreferrer" href='https://www.nac.gov.pl/'><img src={narodoweArchiwumCyfrowe} alt={"Narodowe Archiwum Cyfrowe"} width={90}></img></a>
              <a target="_blank" rel="noopener noreferrer" href='https://agad.gov.pl/'><img src={archiwumGlowneAktDawnych} alt={"Archiwum Główne Akt Dawnych"} width={140}></img></a>
              <a target="_blank" rel="noopener noreferrer" href='https://poznan.ap.gov.pl/'><img src={archiwumPanstwowePoznan} alt={"Archiwum Państwowe w Poznaniu"} width={140}></img></a>
              <a target="_blank" rel="noopener noreferrer" href='https://nim.gov.pl/'><img src={narodowyInstytutMuzeow} alt={"Narodowy Instytut Muzeów"} width={142}></img></a>
              <a target="_blank" rel="noopener noreferrer" href='https://wawel.krakow.pl/'><img src={zamekKrolewskiNaWawelu} alt={"Zamek Królewski na Wawelu"} width={71}></img></a>
              <a target="_blank" rel="noopener noreferrer" href='https://www.wilanow-palac.pl/'><img src={muzeumPalacuKrolaJana} alt={"Muzeum Pałacu Króla Jana III w Wilanowie"} width={158}></img></a>
              <a target="_blank" rel="noopener noreferrer" href='https://www.mng.gda.pl/'><img src={muzeumNarodoweGdansk} alt={"Muzeum Narodowe w Gdańsku"} width={85}></img></a>
              <a target="_blank" rel="noopener noreferrer" href='https://muzeumkrakowa.pl/'><img src={muzeumHistoryczneKrakow} alt={"Muzeum Krakowa"} width={77}></img></a>
              <a target="_blank" rel="noopener noreferrer" href='https://www.pw.edu.pl/'><img src={politechnikaWarszawska} alt={"Politechnika Warszawska"} width={60}></img></a>
              <a target="_blank" rel="noopener noreferrer" href='https://upjp2.edu.pl/'><img src={uniwersytetPapieski} alt={"Uniwersytet Papieski Jana Pawła II w Krakowie"} width={170}></img></a>
            </div>
          </div>


        </div>
        <Footer />
      </div>
    </>
  );
};

export default WelcomePage;
