
import ReferenceWhite from "./ReferenceWhite";

const LabToXyz = (l, a, b, sourceWhite) => {

    // Reference white values
    const { Xn, Yn, Zn } = ReferenceWhite(sourceWhite);

    const fYYn = (l + 16) / 116;
    const fXXn = a / 500 + fYYn;
    const fZZn = fYYn - b / 200;

    const X = (fXXn > 6 / 29) ? Xn * Math.pow(fXXn, 3) : (108 / 841) * Xn * (fXXn - 4 / 29);
    const Y = (fYYn > 6 / 29) ? Yn * Math.pow(fYYn, 3) : (108 / 841) * Yn * (fYYn - 4 / 29);
    const Z = (fZZn > 6 / 29) ? Zn * Math.pow(fZZn, 3) : (108 / 841) * Zn * (fZZn - 4 / 29);

    return { X, Y, Z };
};

export default LabToXyz;
