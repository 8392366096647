import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Alert, { alertVariants, wassefWarning, rlabWarning } from '../Alert';
import { ParseMultipleLAB } from './ParseMultipleLab';
import TableMultiple from './TableMultiple';
import LabSelectForm from "../SelectForm/LabSelectForm";
import * as SelectOptions from "../SelectForm/SelectOptions";
import LuvToXyz from "../math/LuvToXyz";
import XyzToRgb from '../math/XyzToRgb';
import rgbToHex from '../math/RgbToHex';
import MethodSelect from "../SelectForm/MethodSelect";
import resetIcon from "../../assets/reset-icon.svg"

function CheckIfValuesCountIsCorrect(labList) {
    if (labList.length > 0) {
        if (Math.floor(labList[labList.length - 1].length / 3) !== 1) {
            return false
        }
    }
    return true
}
const MultipleLuvToRgb = () => {
    const { t } = useTranslation();
    const [inputText, setInputText] = useState('');
    const parsedInput = ParseMultipleLAB(inputText);
    const handleInputChange = (e) => {
        setInputText(e.target.value);
    };
    const handleClearValues = () => {
        setInputText('');
    };
    const methodchoice = [
        { value: "Bradford", label: "Bradford, linear, compliant with ICC.1:2022 version 4.4, Annex E" },
        { value: "BradfordNonLinear", label: "Bradford, nonlinear" },
        { value: "Von Kries", label: "Von Kries" },
        { value: "XYZ Scaling", label: "XYZ Scaling" },
        { value: "ciecat94", label: "CIECAT94" },
        { value: "Xcmccat97", label: "CMCCAT97" },
        { value: "Xcmccat2000", label: "CMCCAT2000" },
        { value: "Xcat02", label: "CAT02" },
        { value: "rlab", label: "RLAB" },
        { value: "wassef", label: "Wassef" },
        { value: "none", label: t('none') },
    ];

    const [whitepoint, setWhitepoint] = useState(SelectOptions.whitepoint[4]);
    const [conversionToColorSpace, setConversionToColorSpace] = useState(SelectOptions.conversionToColorSpace[0]);
    const [method, setMethod] = useState(methodchoice[0]);
    const [destinationWhite, setDestinationWhite] = useState();

    //If source white i destination white are the same, change method to "none" 
    useEffect(() => {
        if (conversionToColorSpace.label.includes(whitepoint.label)) {
            setMethod(methodchoice.find((method) => method.value === "none"));
        } else {
            setMethod(methodchoice.find((method) => method.value === "Bradford"));
        }
    }, [conversionToColorSpace, whitepoint]);


    useEffect(() => {
        if (conversionToColorSpace && conversionToColorSpace.whitepoint) {
            const correspondingWhitepoint = SelectOptions.whitepoint.find((wp) => wp.value === conversionToColorSpace.whitepoint);
            if (correspondingWhitepoint) {
                setDestinationWhite(correspondingWhitepoint);
            }
        }
    }, [conversionToColorSpace]);

    const [showRlabWarning, setShowRlabWarning] = useState(false);
    const [showWassefWarning, setShowWassefWarning] = useState(false);
    useEffect(() => {
        if (method.value === "rlab") {
            setShowRlabWarning(true);
            setShowWassefWarning(false);
        } else if (method.value === "wassef") {
            setShowRlabWarning(false);
            setShowWassefWarning(true);
        } else {
            setShowRlabWarning(false);
            setShowWassefWarning(false);
        }
    }, [method]);

    const methodSelect = MethodSelect(method)


    const input = (
        <div>
            <form>
                <div className="lab-form-select-container">
                    <label>
                        {t("whitepoint")}
                        <LabSelectForm
                            value={whitepoint}
                            onChange={(selectedOption) => setWhitepoint(selectedOption)}
                            options={SelectOptions.whitepoint}
                        />
                    </label>
                    <label>
                        {t("method")}
                        <LabSelectForm
                            value={method}
                            onChange={(selectedOption) => setMethod(selectedOption)}
                            options={methodchoice}
                        />
                    </label>
                    {methodSelect.jsx()}
                    {showWassefWarning && wassefWarning()}
                    {showRlabWarning && rlabWarning()}
                    <label>
                        {t("space")}
                        <LabSelectForm
                            value={conversionToColorSpace}
                            onChange={(selectedOption) => setConversionToColorSpace(selectedOption)}
                            options={SelectOptions.conversionToColorSpace}
                        />
                    </label>
                </div>
            </form>
        </div>
    );

    const Range = t('Range');
    const bit8Label = `8-${t('bit')}`;
    const NoRoundingLabel = `8-${t('bit')}${t('NoRounding')}`;
    const bit10Label = `10-${t('bit')}`;
    const bit15Label = `15+1-${t('bit')}`;
    const bit16Label = `16-${t('bit')}`;
    const bit32Label = `32-${t('bit')}`;
    const Preview = t('Preview');
    const row = t('row');


    const columns = [row, 'L*u*v*', Range, bit8Label, NoRoundingLabel, bit10Label, bit15Label, bit16Label, bit32Label, 'HEX', Preview];
    const data = parsedInput.map(([l, a, b], index) => {

        let { X, Y, Z } = LuvToXyz(l, a, b, whitepoint.value)
        const colorTransformation = {
            adaptationMethod: method.value,
            sourceColorWhite: whitepoint.value,
            destinationColorSpace: conversionToColorSpace.value,
            destinationColorWhite: conversionToColorSpace.whitepoint,
            methodSelect: methodSelect.misc,
        };


        const {
            R01, G01, B01,
            R8bit, G8bit, B8bit,
            NR8b, NG8b, NB8b,
            R15, G15, B15,
            R10, G10, B10,
            R16, G16, B16,
            R32, G32, B32,
        } = XyzToRgb(X, Y, Z, colorTransformation)
        const hexValue = rgbToHex(R8bit, G8bit, B8bit);

        return {
            [row]: index + 1,
            'L*u*v*': `${l} ${a} ${b}`,
            [Range]: `${parseFloat(R01).toFixed(4)} ${parseFloat(G01).toFixed(4)} ${parseFloat(B01).toFixed(4)}`,
            [bit8Label]: `${R8bit} ${G8bit} ${B8bit}`,
            [NoRoundingLabel]: `${NR8b} ${NG8b} ${NB8b}`,
            [bit10Label]: `${R10} ${G10} ${B10}`,
            [bit15Label]: `${R15} ${G15} ${B15}`,
            [bit16Label]: `${R16} ${G16} ${B16}`,
            [bit32Label]: `${R32} ${G32} ${B32}`,
            HEX: `${hexValue}`,
            [Preview]: (<div style={{ backgroundColor: `${hexValue}`, width: '150px', height: '35px' }} />)
        };
    });

    return (
        <div>
            <div>
                <div className="multiple-input-title" >
                    <h3 className="title">{t('multipleValues')}  L*u*v*</h3>
                    <button
                        type="button"
                        onClick={handleClearValues}
                        className="clear-button"
                        style={{marginTop:"30px"}}
                    >
                        <img src={resetIcon} className="button-icon" alt="button" />
                        {t("clear")}
                    </button>
                </div>
                <textarea
                    value={inputText}
                    onChange={handleInputChange}
                    placeholder={t('enter') + ' L*u*v* ' + t('multiplePlaceholder')}
                    style={{ width: '100%', minHeight: 100, resize: 'vertical', borderColor: '#E5E5E5', marginBottom: '20px' }} />
            </div>
            <Alert
                variant={alertVariants.warning}
                message={t("incorrectNumberCount")}
                show={!CheckIfValuesCountIsCorrect(parsedInput)}
            />
            {input}
            <div>
                <h3 className="title">{t('multipleTitle')}</h3>
                <TableMultiple data={data} columns={columns} />
            </div>
        </div>
    );
};

export default MultipleLuvToRgb;
