import ReferenceWhite from "./ReferenceWhite";
import iconFiles from "../../assets/Files.svg";
import { useTranslation } from "react-i18next";

const epsilon = 0.0001

export const ColorInfo = (l, a, b, X, Y, Z, sX, sY, sZ, hex, adaptation, destinationLab) => {
    const { t } = useTranslation();
    const copyHex = () => {
        const removeHash = hex.startsWith("#") ? hex.slice(1) : hex;
        navigator.clipboard.writeText(removeHash);
      };    

    //Optical Density
    const OD = sY < epsilon ? "> 4" : `= ${(-Math.log10(sY)).toFixed(4)}`;
    //LCh
    const LchL = l * 1.0
    const lchC = (Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2)))
    if (Math.abs(a) < epsilon) { a = 0 }
    if (Math.abs(b) < epsilon) { b = 0 }
    const ang = Math.atan2(b, a) * 360.0 / 2.0 / Math.PI
    const lchH = ang >= 0 ? ang : ang + 360;

    //Luv
    const White = ReferenceWhite(adaptation.sourceColorWhite)
    const denominatorUP = (sX + 15.0 * sY + 3.0 * sZ)
    let up, vp
    if (denominatorUP === 0) { up = 0.0; vp = 0.0 }
    else {
        up = (4.0 * sX) / denominatorUP;
        vp = (9.0 * sY) / denominatorUP;
    }
    const unp = (4.0 * White.Xn) / (White.Xn + 15.0 * White.Yn + 3.0 * White.Zn)
    const vnp = (9.0 * White.Yn) / (White.Xn + 15.0 * White.Yn + 3.0 * White.Zn)
    let luvU = 13.0 * l * (up - unp)
    let luvV = 13.0 * l * (vp - vnp)
    //CIELUV saturation
    let Suv
    if (Math.abs(l) < epsilon) { Suv = 0.0 } else {
        Suv = 13 * Math.sqrt(Math.pow(up - unp, 2) + Math.pow(vp - vnp, 2))
    }
    const Cuv = Math.sqrt(Math.pow(luvU, 2) + Math.pow(luvV, 2))

    if (Math.abs(luvU) < epsilon) { luvU = 0 }
    if (Math.abs(luvV) < epsilon) { luvV = 0 }
    const ang2 = Math.atan2(luvV, luvU) * 360.0 / 2.0 / Math.PI
    const huv = ang2 >= 0 ? ang2 : ang2 + 360;

    const table = (
        <>
            <div className="hex">
                HEX: {hex}
                <img
                    className="IconFiles"
                    src={iconFiles}
                    onClick={copyHex}
                    alt="Copy"
                />
            </div>
            <div className="xyz">
                {t('adapted')} X<sub>a</sub>  = {X.toFixed(4)}, Y<sub>a</sub>  = {Y.toFixed(4)}, Z<sub>a</sub>  = {Z.toFixed(4)}
            </div>
            <div className="xyz">
                {t('after')} L<sub>a</sub> *= {parseFloat(destinationLab.l).toFixed(2)}, a<sub>a</sub> *= {Number(destinationLab.a).toFixed(2)}, b<sub>a</sub> *= {Number(destinationLab.b).toFixed(2)}
            </div>
            <div className="xyz">
                {t('preAdapted')} X = {sX.toFixed(4)}, Y = {sY.toFixed(4)}, Z = {sZ.toFixed(4)}
            </div>
            <div className="xyz">
                L* = {LchL.toFixed(4)}, C* = {lchC.toFixed(4)}, h = {lchH.toFixed(4)}°
            </div>
            <div className="xyz">
                L* = {LchL.toFixed(4)}, u* = {luvU.toFixed(4)}, v* = {luvV.toFixed(4)}
            </div>
            <div className="xyz">
                L* = {LchL.toFixed(4)}, C*<sub>uv</sub> = {Cuv.toFixed(4)}, h<sub>uv</sub> = {huv.toFixed(4)}°
            </div>
            <div className="xyz">
                {t('saturation')} s<sub>uv</sub> = {Suv.toFixed(4)}
            </div>
            <div className="xyz">{t('OD')}, OD =  -log<sub>10</sub>Y {OD}</div>
        </>
    );
    
    return { table, luvU, luvV }
};
