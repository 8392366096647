const EOTF = (sR, sG, sB, RGBSpace) => {
    let R, G, B, gamma;
    switch (RGBSpace) {
        case "sRGB":
            gamma = 2.4;
            R = (sR <= 0.04045) ? sR / 12.92 : Math.pow(((sR + 0.055) / 1.055), gamma);
            G = (sG <= 0.04045) ? sG / 12.92 : Math.pow(((sG + 0.055) / 1.055), gamma);
            B = (sB <= 0.04045) ? sB / 12.92 : Math.pow(((sB + 0.055) / 1.055), gamma);
            break;
        case "Rec709full":
            gamma = 2.222;
            R = (sR < 0.081) ? 0.222 * sR :Math.pow(0.91 * sR + 0.09, gamma);
            G = (sG < 0.081) ? 0.222 * sG :Math.pow(0.91 * sG + 0.09, gamma);
            B = (sB < 0.081) ? 0.222 * sB :Math.pow(0.91 * sB + 0.09, gamma);
            break;
        case "Rec709normative":
            gamma = 2.222;
            R = (sR < 0.081) ? 0.222 * sR :Math.pow(0.91 * sR + 0.09, gamma);
            G = (sG < 0.081) ? 0.222 * sG :Math.pow(0.91 * sG + 0.09, gamma);
            B = (sB < 0.081) ? 0.222 * sB :Math.pow(0.91 * sB + 0.09, gamma);
            break;
        case "Adobe RGB (1998)":
            gamma = 2.19921875;
            R = Math.pow(sR, gamma);
            G = Math.pow(sG, gamma);
            B = Math.pow(sB, gamma);
            break;
        case "Display P3":
            gamma = 2.4;
            R = (sR <= 0.04045) ? sR / 12.92 : Math.pow((sR + 0.055) / 1.055, gamma);
            G = (sG <= 0.04045) ? sG / 12.92 : Math.pow((sG + 0.055) / 1.055, gamma);
            B = (sB <= 0.04045) ? sB / 12.92 : Math.pow((sB + 0.055) / 1.055, gamma);
            break;
        case "ECIv2":
            gamma = 3.0;
            R = (sR < 0.08) ? 0.111 * sR : Math.pow(0.862 * sR + 0.138, gamma);
            B = (sB < 0.08) ? 0.111 * sB : Math.pow(0.862 * sB + 0.138, gamma);
            G = (sG < 0.08) ? 0.111 * sG : Math.pow(0.862 * sG + 0.138, gamma);
            break;
        case "ProPhoto": 
            gamma = 1.8;
            R = (sR < 0.03125) ? 0.0622829* sR + 0.003473: Math.pow(0.996527 * sR + 0.003473, gamma);
            G = (sG < 0.03125) ? 0.0622829* sG + 0.003473: Math.pow(0.996527 * sG + 0.003473, gamma);
            B = (sB < 0.03125) ? 0.0622829* sB + 0.003473: Math.pow(0.996527 * sB + 0.003473, gamma);
            break;
        default:
            console.log("Nieobsługiwana przestrzeń kolorów.");
    }
    return { R, G, B };
}

export default EOTF;
