import React from 'react';
import '../styles/Footer.css'
import logoPCSS from '../assets/PCSS_logo.svg'
//import logoPSNC from '../assets/PSNC_logo.svg'
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  
  const redirectToPCSS = () => {
    window.location.href = t('PSNClink');
  };
  const logoSrc = i18n.language === 'pl' ? require('../assets/PCSS_logo.svg') : require('../assets/PSNC_logo.svg');

  const redirectToAuthors = () => {
    const urlPath = currentLanguage === 'pl' ? '/konwerter/autorzy' : '/converter/authors';
    navigate(urlPath);
  };
  return (
    <footer className="footer">
      <div className='footer-left' onClick={redirectToPCSS} title={t('PSNClink')}> 
      <img src={logoSrc.default} alt="Logo" className='logofooter' /> 
        <p>{t('Production')} <strong>{t('PSNC')}</strong>, Media Solutions Lab</p>
      </div>
      <div className='footer-right'>
        {/* <a>{t('regulations')}</a>
        <a> {t('contact')}</a>
        <a>{t('policy')}</a> */}
        <a onClick={redirectToAuthors}>{t('authors')}</a>
      </div>
    </footer>
  );
};

export default Footer;
