import ReferenceWhite from "./ReferenceWhite";

const LuvToXyz = (l, u, v, sourceWhite) => {
    const { Xn, Yn, Zn } = ReferenceWhite(sourceWhite);
    const epsilon = Math.pow(6 / 29, 3);
    const kappa = 903.292 // to confirm

    const u0 = 4 * Xn / (Xn + 15 * Yn + 3 * Zn)
    const v0 = 9 * Yn / (Xn + 15 * Yn + 3 * Zn)

    /*
    const a = 1 / 3 * (((52 * l) / (u + 13 * l * u0)) - 1)
    const b = -5 * Yn
    const c = -1 / 3
    const d = Yn * ((39 * l / (v + 13 * l * v0)) - 5)

    const X = (Math.abs(a - c) > 1e-10) ? (d - b) / (a - c) : (d - b) / (1e-10); // Prevent division by 0
    const Y = l > kappa * epsilon ? Math.pow(((l + 16) / 116), 3) : l / kappa
    const Z = X * a + b
    */
    const up = u / (13 * l) + u0
    const vp = v / (13 * l) + v0
    var yp = (l + 16) / 116


    const Y = (Math.pow(yp, 3) > epsilon) ? Math.pow(yp, 3) : (yp - 16 / 116) / 7.787 // https://www.easyrgb.com/en/math.php
    const X = -(9 * Y * up) / ((up - 4) * vp - up * vp)
    const Z = (9 * Y - (15 * vp * Y) - (vp * X)) / (3 * vp)

    return { X, Y, Z };
};

export default LuvToXyz;
