import React, { useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import DeltaEResults from "../DeltaEResults";
import RgbResults from "../RgbResults";
import LuvForm from "../inputForms/LuvForm";
import RgbToHex from "../math/RgbToHex";
import { rgbAlert, deltaEAlert } from "../Alert";
import { ColorInfo2 } from "../math/ColorInfo2";
import "../../styles/App.css";
import plusCircle from "../../assets/Plus-circle.svg";

const LuvtoRGB = () => {

    const { t } = useTranslation();
    const [showLab2, setShowLab2] = useState(false);
    const [buttonVisible, setButtonVisible] = useState(true);
    const handleShowLab2 = () => {
        setShowLab2(true);
        setButtonVisible(false);
    };
    const handleHideLab2 = () => {
        setShowLab2(false);
        setButtonVisible(true);
    };
    const { input: input1, data: xyz1, colorTransformation: colorTransformation1 } = LuvForm(1);
    const { input: input2, data: xyz2, colorTransformation: colorTransformation2 } = LuvForm(2, handleHideLab2);

    const RgbResults1 = RgbResults(xyz1.X, xyz1.Y, xyz1.Z, colorTransformation1);
    const RgbResults2 = RgbResults(xyz2.X, xyz2.Y, xyz2.Z, colorTransformation2);
    
    const misc1 = ColorInfo2(xyz1.l, xyz1.a, xyz1.b, RgbResults1.data.Xd, RgbResults1.data.Yd, RgbResults1.data.Zd, xyz1.X, xyz1.Y, xyz1.Z, RgbResults1.data.hex, colorTransformation1)
    const misc2 = ColorInfo2(xyz2.l, xyz2.a, xyz2.b, RgbResults1.data.Xd, RgbResults1.data.Yd, RgbResults1.data.Zd, xyz2.X, xyz2.Y, xyz2.Z, RgbResults1.data.hex, colorTransformation1)

    const lab1 = {
        l: xyz1.l, a: xyz1.a, b: xyz1.b,
        R8bit: RgbResults1.data.R8bit, G8bit: RgbResults1.data.G8bit, B8bit: RgbResults1.data.B8bit,
        luvU: xyz1.luvU, luvV: xyz1.luvV
    }
    const lab2 = {
        l: xyz2.l, a: xyz2.a, b: xyz2.b,
        R8bit: RgbResults2.data.R8bit, G8bit: RgbResults2.data.G8bit, B8bit: RgbResults2.data.B8bit,
        luvU: parseFloat(xyz2.luvU), luvV: parseFloat(xyz2.luvV)
    }
    const DeltaEResults1 = DeltaEResults(lab1, lab2);

    const deltaAlert = deltaEAlert(colorTransformation1.sourceColorWhite, colorTransformation2.sourceColorWhite)
    const alertFirstRgb = rgbAlert(
        RgbResults1.rgbAlertData.ExtendedR,
        RgbResults1.rgbAlertData.ExtendedG,
        RgbResults1.rgbAlertData.ExtendedB
    );
    const alertSecondRgb = rgbAlert(
        RgbResults2.rgbAlertData.ExtendedR,
        RgbResults2.rgbAlertData.ExtendedG,
        RgbResults2.rgbAlertData.ExtendedB
    );
    const hex1 = RgbToHex(
        RgbResults1.data.R8bit,
        RgbResults1.data.G8bit,
        RgbResults1.data.B8bit
    );
    const hex2 = RgbToHex(
        RgbResults2.data.R8bit,
        RgbResults2.data.G8bit,
        RgbResults2.data.B8bit
    );

    const goTo = (elementRef) => () => {
        if (elementRef && elementRef.current) {
            elementRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    const results1Ref = useRef(null);
    const results2Ref = useRef(null);
    const resultsDeltaRef = useRef(null);

    const resultsPreview = (
        <div className="resutlsPreview">
            <div className="resutlsPreview-text-container">
                <div className="resutlsPreview-color-text">
                    {t('color')} 1
                    <br />
                    <b>HEX: {hex1}</b>
                    <br />
                    <button className="goToButton" onClick={goTo(results1Ref)}>{t('goToResults')}</button>
                </div>
                <div className="resutlsPreview-color-text">
                    {t('color')} 2
                    <br />
                    <b>HEX: {hex2}</b>
                    <br />
                    <button className="goToButton" onClick={goTo(results2Ref)}>{t('goToResults')}</button>
                </div>
            </div>
            <div className="resutlsPreview-color-text2">{t('ColorPreview')}</div>
            <div className='resutlsPreview-color-container'>
                <div className='resutlsPreview-color' style={{ backgroundColor: hex1 }} />
                <div className='resutlsPreview-color' style={{ backgroundColor: hex2 }} />
            </div>
            <button className="goToButton" onClick={goTo(resultsDeltaRef)}>{t('checkDiff')}</button>
        </div>
    );

    return (
        <>
            <div className="input-forms-container">
                {input1}
                {showLab2 && resultsPreview}
            </div>
            <div style={{ marginTop: "10px" }}>{alertFirstRgb}</div>
            {buttonVisible && (
                <button className="addLabButton" onClick={handleShowLab2}>
                    <img src={plusCircle} alt="plus-circle-icon" className="plusCircle-icon" />
                    {t('addLUV')}
                </button>
            )}
            {showLab2 && input2}
            <div style={{ marginTop: "10px" }}>{alertSecondRgb}</div>
            <div className="table-title">
                <p className="colorText" ref={results1Ref}>{t('color')} 1 -
                    <b> {t('results')} </b>
                </p>
            </div>
            {RgbResults1.table}
            {misc1.table}
            {showLab2 &&
                <div className="table-title">
                    <p className="colorText" ref={results1Ref}>{t('color')} 2 -
                        <b> {t('results')} </b>
                    </p>
                </div>}
            {showLab2 && RgbResults2.table}
            {showLab2 && misc2.table}
            {showLab2 && (
                <h3 className="title" ref={resultsDeltaRef}>
                    {t('ColorDiff')}
                </h3>
            )}
            {showLab2 && deltaAlert}
            {showLab2 && <p className="delta-note">{t('deltaNoteBefore')}</p>}
            {showLab2 && DeltaEResults1}
        </>
    );
};

export default LuvtoRGB;
