const XYYtoXYZ = (x, y, Y) => {
    let X, Z

    if (y === 0) {
        return { X: 0, Y: 0, Z: 0 }
    } else {
        X = x * Y / y
        Y = Y
        Z = ((1 - x - y) * Y) / y

        return { X, Y, Z };
    }
};

export default XYYtoXYZ;