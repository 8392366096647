function ReferenceWhite(Whitepoint) {
  switch (Whitepoint) {
    case 'D50 1931':
      return { Xn: 0.9642, Yn: 1.0, Zn: 0.8251 };
    case 'D50 1964':
      return { Xn: 0.9672, Yn: 1.0, Zn: 0.8143 };
    case 'D55 1931':
      return { Xn: 0.9568, Yn: 1.0, Zn: 0.9214 };
    case 'D55 1964':
      return { Xn: 0.9580, Yn: 1.0, Zn: 0.9093 };
    case 'D65 1931':
      return { Xn: 0.9504, Yn: 1.0, Zn: 1.0888 };
    case 'D65 1964':
      return { Xn: 0.9481, Yn: 1.0, Zn: 1.0732 };
    case 'D75 1931':
      return { Xn: 0.9497, Yn: 1.0, Zn: 1.2261 };
    case 'D75 1964':
      return { Xn: 0.9442, Yn: 1.0, Zn: 1.2064 };
    case 'A 1931':
      return { Xn: 1.0985, Yn: 1.0, Zn: 0.3558 };
    case 'A 1964':
      return { Xn: 1.1114, Yn: 1.0, Zn: 0.3520 };
    case 'C 1931':
      return { Xn: 0.9807, Yn: 1.0, Zn: 1.1822 };
    case 'C 1964':
      return { Xn: 0.9729, Yn: 1.0, Zn: 1.1614 };
    default:
      console.error('Invalid Whitepoint');
      return { Xn: 0, Yn: 0, Zn: 0 };
  }
}
export default ReferenceWhite