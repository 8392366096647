import ReferenceWhite from "./ReferenceWhite";

const XyzToLuv = (X, Y, Z, sourceWhite) => {
    const { Xn, Yn, Zn } = ReferenceWhite(sourceWhite);
    const epsilon = Math.pow(6 / 29, 3);
    const kappa = 903.292 // to confirm

    const u0 = 4 * Xn / (Xn + 15 * Yn + 3 * Zn)
    const v0 = 9 * Yn / (Xn + 15 * Yn + 3 * Zn)

    let denominator = X + 15 * Y + 3 * Z;
    if (denominator === 0) { denominator = 1e-10 }
    
    const up = 4 * X / denominator
    const vp = 9 * Y / denominator

    const yr = Y / Yn

    const l = yr > epsilon ? 116 * Math.pow(yr, 1 / 3) - 16 : kappa * yr
    const u = 13 * l * (up - u0)
    const v = 13 * l * (vp - v0)

    return { l, u, v };
};

export default XyzToLuv;
