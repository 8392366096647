import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../styles/LabForm.css";
import LabSelectForm from "../SelectForm/LabSelectForm";

function normalizeInput(input) {
    input = input.replaceAll(/[^0-9.-]/gim, (m) => (m === "," ? "." : ""));
    let dot = input.search(/\./gim);
    if (dot >= 0) {
        input =
            input.substr(0, dot + 1) + input.substr(dot).replaceAll(/\./gim, "");
    }
    if (input.length === 1) {
        input = input.replaceAll(/[^0-9-]/gim, "");
    }
    input = input.slice(0, 1) + input.slice(1).replaceAll("-", "");
    return input;
}
const Dchoice = [
    { value: "0", label: "0.0 (for CRT monitors)" },
    { value: "1", label: "1.0 (default)" },
];

const oldVersionChoice = [
    { value: "false", label: "false" },
    { value: "true", label: "true" },
];
const degreeModeChoice = [
    { value: "zero", label: "zero" },
    { value: "full", label: "full" },    { value: "automatic", label: "automatic" },
];

const MethodSelect = (method) => {
    const { t } = useTranslation();
    // CIECAT94
    const [Yb, setYb] = useState("20.0");
    const [D, setD] = useState("1.0"); 
    const [La, setLa] = useState(60); //80
    const [oldVersion, setOldVersion] = useState(oldVersionChoice[0]);

    // CMCCAT97
    const [La97, setLa97] = useState(60);
    const [F, setF] = useState("1.0");
    const [degreeMode, setDegreeMode] = useState(degreeModeChoice[2]);

    // CMCCAT2000
    const [La1_2000, setLa1_2000] = useState(60);
    const [La2_2000, setLa2_2000] = useState(60);
    const [F2000, setF2000] = useState("1") 

    // CAT02
    const [La_CAT02, setLa_CAT02] = useState(60); 
    const [F_CAT02, setF_CAT02] = useState("1") 

    // rlab
    const [La_rlab, setLa_rlab] = useState(60);
    const [D_rlab, setD_rlab] = useState(1.0);

    const jsx = () => {
        switch (method.value) {
            case "ciecat94":
                return (
                    <div className="method-select">
                        <label >
                            <span><i>Y</i><sub>b</sub> {t("Yb")}</span>
                            <input
                                type="text"
                                value={Yb}
                                onChange={(e) => setYb(normalizeInput(e.target.value))}
                                onFocus={(e) => e.target.select()}
                                onBlur={(e) => {
                                    if (e.target.value.trim() === "") {
                                        setYb(0);
                                    }
                                }}
                                className="lab-input"
                                style={{ fontSize: "16px" }}
                            />
                        </label>
                        <label>
                            <span><i>L</i><sub>a</sub> {t("La")} 60 cd/m<sup>2</sup>: </span>
                            <input
                                type="text"
                                value={La}
                                onChange={(e) => setLa(normalizeInput(e.target.value))}
                                onFocus={(e) => e.target.select()}
                                onBlur={(e) => {
                                    if (e.target.value.trim() === "") {
                                        setLa(0);
                                    }
                                }}
                                className="lab-input"
                                style={{ fontSize: "16px" }}
                            />
                        </label>
                        <label>
                            <span><i>D</i> {t("D")}</span>
                            <input
                                type="text"
                                value={D}
                                onChange={(e) => setD(normalizeInput(e.target.value))}
                                onFocus={(e) => e.target.select()}
                                onBlur={(e) => {
                                    if (e.target.value.trim() === "") {
                                        setD(0);
                                    }
                                }}
                                className="lab-input"
                                style={{ fontSize: "16px" }}
                            />
                        </label>
                        <label>
                            <span>{t("oldVersion")}</span>
                            <LabSelectForm
                                value={oldVersion}
                                onChange={(selectedOption) => { setOldVersion(selectedOption); }}
                                options={oldVersionChoice}
                            />
                        </label>
                    </div>
                );
            case "Xcmccat97":
                return (
                    <div className="method-select">
                        <label>
                            <span><i>L</i><sub>a</sub> {t("La")} 60 cd/m<sup>2</sup>: </span>
                            <input
                                type="text"
                                value={La97}
                                onChange={(e) => setLa97(normalizeInput(e.target.value))}
                                onFocus={(e) => e.target.select()}
                                onBlur={(e) => {
                                    if (e.target.value.trim() === "") {
                                        setLa97(0);
                                    }
                                }}
                                className="lab-input"
                                style={{ fontSize: "16px" }}
                            />
                        </label>
                        <label>
                            <span><i>F</i> {t("F")}</span>
                            <input
                                type="text"
                                value={F}
                                onChange={(e) => setF(normalizeInput(e.target.value))}
                                onFocus={(e) => e.target.select()}
                                onBlur={(e) => {
                                    if (e.target.value.trim() === "") {
                                        setF(0);
                                    }
                                }}
                                className="lab-input"
                                style={{ fontSize: "16px" }}
                            />
                        </label>
                        <label>
                            <span>{t("adaptationDegreeMode")}</span>
                            <LabSelectForm
                                value={degreeMode}
                                onChange={(selectedOption) => { setDegreeMode(selectedOption); }}
                                options={degreeModeChoice}
                            />
                        </label>
                    </div>
                );
            case "Xcmccat2000":
                return (
                    <div className="method-select">
                        <label>
                            <span><i>L</i><sub>a1</sub>{t("La1")} 60 cd/m<sup>2</sup>: </span>
                            <input
                                type="text"
                                value={La1_2000}
                                onChange={(e) => setLa1_2000(normalizeInput(e.target.value))}
                                onFocus={(e) => e.target.select()}
                                onBlur={(e) => {
                                    if (e.target.value.trim() === "") {
                                        setLa1_2000(0);
                                    }
                                }}
                                className="lab-input"
                                style={{ fontSize: "16px" }}
                            />
                        </label>
                        <label>
                            <span><i>L</i><sub>a2</sub> {t("La2")} 60 cd/m<sup>2</sup>: </span>
                            <input
                                type="text"
                                value={La2_2000}
                                onChange={(e) => setLa2_2000(normalizeInput(e.target.value))}
                                onFocus={(e) => e.target.select()}
                                onBlur={(e) => {
                                    if (e.target.value.trim() === "") {
                                        setLa2_2000(0);
                                    }
                                }}
                                className="lab-input"
                                style={{ fontSize: "16px" }}
                            />
                        </label>
                        <label>
                            <span><i>F</i> {t("F")}</span>
                            <input
                                type="text"
                                value={F2000}
                                onChange={(e) => setF2000(normalizeInput(e.target.value))}
                                onFocus={(e) => e.target.select()}
                                onBlur={(e) => {
                                    if (e.target.value.trim() === "") {
                                        setF2000(0);
                                    }
                                }}
                                className="lab-input"
                                style={{ fontSize: "16px" }}
                            />
                        </label>
                    </div>
                )
            case "Xcat02":
                return (
                    <div className="method-select">
                        <label>
                            <span><i>L</i><sub>a</sub> {t("LaCat02")} 60 cd/m<sup>2</sup> {t("LaCat02.2")}: </span>
                            <input
                                type="text"
                                value={La_CAT02}
                                onChange={(e) => setLa_CAT02(normalizeInput(e.target.value))}
                                onFocus={(e) => e.target.select()}
                                onBlur={(e) => {
                                    if (e.target.value.trim() === "") {
                                        setLa_CAT02(0);
                                    }
                                }}
                                className="lab-input"
                                style={{ fontSize: "16px" }}
                            />
                        </label>
                        <label>
                            <span><i>F</i> {t("F")}</span>
                            <input
                                type="text"
                                value={F_CAT02}
                                onChange={(e) => setF_CAT02(normalizeInput(e.target.value))}
                                onFocus={(e) => e.target.select()}
                                onBlur={(e) => {
                                    if (e.target.value.trim() === "") {
                                        setF_CAT02(0);
                                    }
                                }}
                                className="lab-input"
                                style={{ fontSize: "16px" }}
                            />
                        </label>
                    </div>
                )
            case "rlab":
                return (
                    <div className="method-select">
                        <label>
                            <span><i>L</i><sub>a</sub> {t("LaRlab")} 60 cd/m<sup>2</sup>: </span>
                            <input
                                type="text"
                                value={La_rlab}
                                onChange={(e) => setLa_rlab(normalizeInput(e.target.value))}
                                onFocus={(e) => e.target.select()}
                                onBlur={(e) => {
                                    if (e.target.value.trim() === "") {
                                        setLa_rlab(0);
                                    }
                                }}
                                className="lab-input"
                                style={{ fontSize: "16px" }}
                            />
                        </label>
                        <label>
                            <span><i>D</i> {t("D")}</span>
                            <input
                                type="text"
                                value={D_rlab}
                                onChange={(e) => setD_rlab(normalizeInput(e.target.value))}
                                onFocus={(e) => e.target.select()}
                                onBlur={(e) => {
                                    if (e.target.value.trim() === "") {
                                        setD_rlab(0);
                                    }
                                }}
                                className="lab-input"
                                style={{ fontSize: "16px" }}
                            />
                        </label>
                    </div>
                )
            default:

                return null;
        }
    }
    return {
        jsx,
        misc: {
            D, F, Yb, La, La97, oldVersion, // Lra,
            degreeMode,
            La1_2000, La2_2000, F2000,
            F_CAT02, La_CAT02,
            La_rlab, D_rlab
        }
    }
};
export default MethodSelect