import React from 'react';

const TableMultiple = ({ data, columns }) => {
  return (
    <table style={{width: '100%',borderCollapse: 'collapse'}}>
      <thead>
        <tr>
          {columns.map(column => (
            <th
              key={column}
              style={{
                background: '#e5e5e5',
                fontSize: '13px',
                fontWeight: 'normal',
                lineHeight: '28.5px',
                whiteSpace: 'pre-wrap',
              }}
            >
              {column}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {columns.map(column => (
              <td
                key={column}
                style={{
                  padding: '10px',
                  textAlign: 'center',
                  background: '#fff',
                }}
              >
                {row[column]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableMultiple;
