import React, { createContext } from 'react';
import { I18nextProvider } from 'react-i18next';
import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import Header from './components/Header';
import Footer from './components/Footer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from './components/ThemeContext';

export const GlobalContext = createContext();

i18next
    .use(initReactI18next)
    .init({
        interpolation: { escapeValue: false },
        lng: 'pl',
        resources: {
            en: {
                translation: require('./components/Language/en.json'),
            },
            pl: {
                translation: require('./components/Language/pl.json'),
            },
        }
    });

    export const GlobalState = ({ children, showHeaderFooter = true }) => {
        return (
            <GlobalContext.Provider>
            <ThemeProvider>
                <I18nextProvider i18n={i18next}>
                    <BrowserRouter >
                        <div className="app-container">
                            {showHeaderFooter && <Header />}
                            <div className="box">
                                {children}
                            </div>
                            {showHeaderFooter && <Footer />}
                        </div>
                    </BrowserRouter>
                </I18nextProvider>
                </ThemeProvider>
            </GlobalContext.Provider>
        );
    };