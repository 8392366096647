import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Alert, { alertVariants, wassefWarning, rlabWarning } from '../Alert';
import XyzToRgb from '../math/XyzToRgb';
import RGBtoXYZ from "../math/RGBtoXyz";
import rgbToHex from '../math/RgbToHex';
import { ParseMultipleLAB } from './ParseMultipleLab';
import TableMultiple from './TableMultiple';
import LabSelectForm from "../SelectForm/LabSelectForm";
import * as SelectOptions from "../SelectForm/SelectOptions";
import MethodSelect from "../SelectForm/MethodSelect";
import resetIcon from "../../assets/reset-icon.svg"


function CheckIfValuesCountIsCorrect(labList) {
    if (labList.length > 0) {
        if (Math.floor(labList[labList.length - 1].length / 3) !== 1) {
            return false
        }
    }
    return true
}

const MultipleRgbToRgb = () => {
    const { t } = useTranslation();
    const [inputText, setInputText] = useState('');
    const parsedInput = ParseMultipleLAB(inputText);
    const handleInputChange = (e) => {
        setInputText(e.target.value);
    };
    const handleClearValues = () => {
        setInputText('');
    };
    const methodchoice = [
        { value: "Bradford", label: "Bradford, linear, compliant with ICC.1:2022 version 4.4, Annex E" },
        { value: "BradfordNonLinear", label: "Bradford, nonlinear" },
        { value: "Von Kries", label: "Von Kries" },
        { value: "XYZ Scaling", label: "XYZ Scaling" },
        { value: "ciecat94", label: "CIECAT94" },
        { value: "Xcmccat97", label: "CMCCAT97" },
        { value: "Xcmccat2000", label: "CMCCAT2000" },
        { value: "Xcat02", label: "CAT02" },
        { value: "rlab", label: "RLAB" },
        { value: "wassef", label: "Wassef" },
        { value: "none", label: t('none') },
    ];

    const [selectedFormat, setSelectedFormat] = useState(format[1]);
    const [method, setMethod] = useState(methodchoice[0]);
    const [sourceSpace, setSourceSpace] = useState(SelectOptions.conversionToColorSpace[3]);
    const [destinationSpace, setDestinationSpace] = useState(SelectOptions.conversionToColorSpace[0]);

    useEffect(() => {
        if (sourceSpace.whitepoint.includes(destinationSpace.whitepoint)) {
            setMethod(methodchoice.find((method) => method.value === "none"));
        } else {
            setMethod(methodchoice.find((method) => method.value === "Bradford"));
        }
    }, [sourceSpace, destinationSpace]);


    const [showRlabWarning, setShowRlabWarning] = useState(false);
    const [showWassefWarning, setShowWassefWarning] = useState(false);
    useEffect(() => {
        if (method.value === "rlab") {
            setShowRlabWarning(true);
            setShowWassefWarning(false);
        } else if (method.value === "wassef") {
            setShowRlabWarning(false);
            setShowWassefWarning(true);
        } else {
            setShowRlabWarning(false);
            setShowWassefWarning(false);
        }
    }, [method]);

    const methodSelect = MethodSelect(method)

    const input = (
        <div>
            <form>
                <div className="lab-form-select-container">
                    <label>
                        Format:
                        <LabSelectForm
                            value={selectedFormat}
                            onChange={(selectedOption) => setSelectedFormat(selectedOption)}
                            options={format}
                        />
                    </label>
                    <label>
                        {t('inputSpace')}
                        <LabSelectForm
                            value={sourceSpace}
                            onChange={(selectedOption) => { setSourceSpace(selectedOption); }}
                            options={SelectOptions.conversionToColorSpace}
                        />
                    </label>
                    <label>
                        {t("method")}
                        <LabSelectForm
                            value={method}
                            onChange={(selectedOption) => { setMethod(selectedOption) }}
                            options={methodchoice}
                        />
                    </label>
                    {methodSelect.jsx()}
                    {showWassefWarning && wassefWarning()}
                    {showRlabWarning && rlabWarning()}
                    <label>
                        {t("outputSpace")}
                        <LabSelectForm
                            value={destinationSpace}
                            onChange={(selectedOption) => setDestinationSpace(selectedOption)}
                            options={SelectOptions.conversionToColorSpace}
                        />
                    </label>
                </div>
            </form>
        </div>
    );

    const Range = t('Range');
    const bit8Label = `8-${t('bit')}`;
    const NoRoundingLabel = `8-${t('bit')}${t('NoRounding')}`;
    const bit10Label = `10-${t('bit')}`;
    const bit15Label = `15+1-${t('bit')}`;
    const bit16Label = `16-${t('bit')}`;
    const bit32Label = `32-${t('bit')}`;
    const Preview = t('Preview');
    const row = t('row');


    const columns = [row, 'RGB', Range, bit8Label, NoRoundingLabel, bit10Label, bit15Label, bit16Label, bit32Label, 'HEX', Preview];


    const data = parsedInput.map(([r, g, b], index) => {

        let R = parseFloat(r);
        let G = parseFloat(g);
        let B = parseFloat(b);
        let x, y;

        switch (selectedFormat.value) {
            case '01':
                break;
            case '8-bit':
                x = (sourceSpace.value === "Rec709normative") ? 16 : 0;
                y = (sourceSpace.value === "Rec709normative") ? 235 : 255;
                break;
            case '10-bit':
                x = (sourceSpace.value === "Rec709normative") ? 64 : 0;
                y = (sourceSpace.value === "Rec709normative") ? 940 : 1023;
                break;
            case '15+1-bit':
                x = (sourceSpace.value === "Rec709normative") ? 2048 : 0;
                y = (sourceSpace.value === "Rec709normative") ? 30080 : 32767;
                break;
            case '16-bit':
                x = (sourceSpace.value === "Rec709normative") ? 4096 : 0;
                y = (sourceSpace.value === "Rec709normative") ? 60160 : 65535;
                break;
            case '32-bit':
                x = (sourceSpace.value === "Rec709normative") ? 268435456 : 0;
                y = (sourceSpace.value === "Rec709normative") ? 3942645760 : 4294967295;
                break;
            default:
                console.error('Unsupported color format');
                break;
        }

        if (selectedFormat.value !== '01') {
            R = (R - x) / (y - x);
            G = (G - x) / (y - x);
            B = (B - x) / (y - x);
        }

        const { X, Y, Z } = RGBtoXYZ(R, G, B, sourceSpace, selectedFormat);
        const colorTransformation = {
            adaptationMethod: method.value,
            sourceColorWhite: sourceSpace.whitepoint,
            destinationColorSpace: destinationSpace.value,
            destinationColorWhite: destinationSpace.whitepoint,
            methodSelect: methodSelect.misc,
        };

        const {
            ClippedR, ClippedG, ClippedB,
            R8bit: R8, G8bit: G8, B8bit: B8,
            NR8b, NG8b, NB8b,
            R15, G15, B15,
            R10, G10, B10,
            R16, G16, B16,
            R32, G32, B32,
        } = XyzToRgb(X, Y, Z, colorTransformation)


        R > 1 ? R = 1 : R = R;
        R < 0 ? R = 0 : R = R;

        G > 1 ? G = 1 : G = G;
        G < 0 ? G = 0 : G = G;

        B > 1 ? B = 1 : B = B;
        B < 0 ? B = 0 : B = B;

        const R8bit = Math.floor(255 * R + 0.5);
        const G8bit = Math.floor(255 * G + 0.5);
        const B8bit = Math.floor(255 * B + 0.5);
        const hexValue = rgbToHex(R8bit, G8bit, B8bit);

        return {
            [row]: index + 1,
            'RGB': `${r} ${g} ${b}`,
            [Range]: `${parseFloat(ClippedR).toFixed(4)} ${parseFloat(ClippedG).toFixed(4)} ${parseFloat(ClippedB).toFixed(4)}`,
            [bit8Label]: `${R8} ${G8} ${B8}`,
            [NoRoundingLabel]: `${NR8b} ${NG8b} ${NB8b}`,
            [bit10Label]: `${R10} ${G10} ${B10}`,
            [bit15Label]: `${R15} ${G15} ${B15}`,
            [bit16Label]: `${R16} ${G16} ${B16}`,
            [bit32Label]: `${R32} ${G32} ${B32}`,
            HEX: `${hexValue}`,
            [Preview]: (<div style={{ backgroundColor: `rgb(${R8bit}, ${G8bit}, ${B8bit})`, width: '100%', height: '35px' }} />)
        };
    });

    return (
        <div>
            <div>
                <div className="multiple-input-title" >
                    <h3 className="title">{t('multipleValues')} RGB</h3>
                    <button
                        type="button"
                        onClick={handleClearValues}
                        className="clear-button"
                        style={{ marginTop: "30px" }}
                    >
                        <img src={resetIcon} className="button-icon" alt="button" />
                        {t("clear")}
                    </button>
                </div>
                <textarea
                    value={inputText}
                    onChange={handleInputChange}
                    placeholder={t('enter') + ' RGB ' + t('multiplePlaceholder')}
                    style={{ width: '100%', minHeight: 100, resize: 'vertical', borderColor: '#E5E5E5', marginBottom: '20px' }} />
            </div>
            <Alert
                variant={alertVariants.warning}
                message={t("incorrectNumberCount")}
                show={!CheckIfValuesCountIsCorrect(parsedInput)}
            />
            {input}
            <div>
                <h3 className="title">{t('multipleTitle')}</h3>
                <TableMultiple data={data} columns={columns} />
            </div>
        </div>
    );
};

export default MultipleRgbToRgb;




const format = [
    { value: "01", label: "0-1 range" },
    { value: "8-bit", label: "8-bit" },
    { value: "10-bit", label: "10-bit" },
    { value: "15+1-bit", label: "15+1-bit" },
    { value: "16-bit", label: "16-bit" },
    { value: "32-bit", label: "32-bit" },
];
