import { useTranslation } from 'react-i18next';
import Copyright from "./copyright";
import "./welcome-page.css"

function Footer() {
  const { t } = useTranslation();
  return (
    <footer>
      {/* <div className="border-disabled border-t w-[100vw]"></div> */}
      <div className="footer-container">
        <Copyright/>
        {/* <div style={{marginTop:"50px"}}>
          <span style={{fontWeight:700}}>{t("contact")}</span><br/>
          Jana Pawła II 10<br/>
          61-139 Poznań<br/>
          mdabrowski@man.poznan.pl
        </div> */}
      </div>
    </footer>
  );
}

export default Footer;
