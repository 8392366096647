import ReferenceWhite from "./ReferenceWhite";

const XyztoLab = (X, Y, Z, sourceWhite) => {
    const epsilon = Math.pow(6 / 29, 3);
    const kappa = 841/108;
    const white = ReferenceWhite(sourceWhite);

    const xr = X / white.Xn;
    const yr = Y / white.Yn;
    const zr = Z / white.Zn;

    let fy = (yr > epsilon) ? Math.cbrt(yr) : ((kappa * yr) + (4 / 29));
    let fx = (xr > epsilon) ? Math.cbrt(xr) : ((kappa * xr) + (4 / 29));
    let fz = (zr > epsilon) ? Math.cbrt(zr) : ((kappa * zr) + (4 / 29));

    const l = 116 * fy - 16;
    const a = 500 * (fx - fy);
    const b = 200 * (fy - fz);

    return { l, a, b };
};

export default XyztoLab;
