
export const whitepoint = [
  { value: "D50 1931", label: "D50, observer CIE 1931, 2°" },
  { value: "D50 1964", label: "D50, observer CIE 1964, 10°" },
  { value: "D55 1931", label: "D55, observer CIE 1931, 2°" },
  { value: "D55 1964", label: "D55, observer CIE 1964, 10°" },
  { value: "D65 1931", label: "D65, observer CIE 1931, 2°" },
  { value: "D65 1964", label: "D65, observer CIE 1964, 10°" },
  { value: "D75 1931", label: "D75, observer CIE 1931, 2°" },
  { value: "D75 1964", label: "D75, observer CIE 1964, 10°" },
  { value: "A 1931", label: "A, observer CIE 1931, 2°" },
  { value: "A 1964", label: "A, observer CIE 1964, 10°" },
  { value: "C 1931", label: "C, observer CIE 1931, 2°" },
  { value: "C 1964", label: "C, observer CIE 1964, 10°" },
];
export const conversionToColorSpace = [
  { value: "sRGB", label: "sRGB IEC 61966-2.1, D65, observer CIE 1931, 2°", whitepoint: "D65 1931" },
  { value: "Rec709normative", label: "ITU-R BT.709-6, normative range, D65, observer CIE 1931, 2°", whitepoint: "D65 1931" },
  { value: "Rec709full", label: "ITU-R BT.709-6, full range, D65, observer CIE 1931, 2°", whitepoint: "D65 1931" },
  { value: "Adobe RGB (1998)", label: "Adobe RGB (1998), D65, observer CIE 1931, 2°", whitepoint: "D65 1931" },
  { value: "Display P3", label: "Display P3, primaries SMPTE-EG-0432-1:2010, D65, observer CIE 1931, 2°", whitepoint: "D65 1931" },
  { value: "ECIv2", label: "eciRGB v2, ISO 22028-4.2, D50, observer CIE 1931, 2°", whitepoint: "D50 1931" },
  { value: "ProPhoto", label: "ProPhoto, ROMM RGB, ISO 22028-2:2013, D50, observer CIE 1931, 2°", whitepoint: "D50 1931" },
];