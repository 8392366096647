import { t } from "i18next";
import React from "react";
import "../styles/Alert.css";

export const alertVariants = {
  warning: {
    backgroundColor: "#FFFADD",
    borderColor: "#E1BD00",
    textColor: "#E1BD00",
    icon: "bi bi-exclamation-triangle",
  },
  success: {
    backgroundColor: "#D4EDDA",
    borderColor: "#147A11",
    textColor: "#147A11",
    icon: "bi bi-check2",
  },
  error: {
    backgroundColor: "#FFE2E3",
    borderColor: "#FFA4A8",
    textColor: "#980107",
    icon: "bi bi-x-lg",
  },
};

export function labFormWarning(l, a, b) {
  var warning;
  if (
    isNaN(l) ||
    isNaN(a) ||
    isNaN(b) ||
    l === "" ||
    a === "" ||
    b === "" ||
    l === null ||
    a === null ||
    b === null
  ) {
    warning = "";
    return warning;
  } else if (
    l >= 0 &&
    l <= 100 &&
    a >= -128 &&
    a <= 128 &&
    b >= -128 &&
    b <= 128
  ) {
    warning = (
      <Alert
        variant={alertVariants.success}
        message={t("inRage")}
        show={true}
      />
    );
    return warning;
  } else {
    warning = (
      <Alert
        variant={alertVariants.warning}
        message={t("outRange")}
        show={true}
      />
    );
    return warning;
  }
}

export function rgbAlert(ExtendedR, ExtendedG, ExtendedB) {
  let showAlert = false;
  let epsilon = 1.0001;
  if (
    ExtendedR < 0 ||
    ExtendedR > epsilon ||
    ExtendedG < 0 ||
    ExtendedG > epsilon ||
    ExtendedB < 0 ||
    ExtendedB > epsilon
  ) {
    showAlert = true;
  }
  return (
    <Alert
      variant={alertVariants.warning}
      message={t("outRGB")}
      show={showAlert}
    />
  );
}
export function deltaEAlert(whitepoint1, whitepoint2) {
  let showAlert = false;
  if (
    whitepoint1 !== whitepoint2
  ) {
    showAlert = true;
  }
  return (
    <Alert
      variant={alertVariants.warning}
      message={t("outWhite")}
      show={showAlert}
    />
  );
}

export function sameSpaceErrorAlert() {

  return (
    <Alert
      variant={alertVariants.warning}
      show={true}
      message={t("sameSpaceError")}
    />
  );
}
export function wassefWarning() {

  return (
    <Alert
      variant={alertVariants.warning}
      show={true}
      message={t("wassefWarning")}
    />
  );
}
export function rlabWarning() {

  return (
    <Alert
      variant={alertVariants.warning}
      show={true}
      message={t("rlabWarning")}
    />
  );
}

export const Alert = ({ variant, message, show = false }) => {
  if (show) {
    return (
      <div
        className="alert-container"
        style={{
          background: variant.backgroundColor,
          border: "1px solid" + variant.borderColor,
        }}
      >
        <i className={variant.icon} style={{ color: variant.textColor }}></i>
        <span
          style={{
            color: variant.textColor,
          }}
        >
          {message}
        </span>
      </div>
    );
  }
};

export default Alert;
