const OETF = (sR, sG, sB, RGBSpace) => {
    let R, G, B, gamma;
    switch (RGBSpace) {
        case "sRGB":
            gamma = 2.4;
            R = (sR <= 0.0031308) ? 12.92 * sR : 1.055 * Math.pow(sR, 1.0 / gamma) - 0.055;
            G = (sG <= 0.0031308) ? 12.92 * sG : 1.055 * Math.pow(sG, 1.0 / gamma) - 0.055;
            B = (sB <= 0.0031308) ? 12.92 * sB : 1.055 * Math.pow(sB, 1.0 / gamma) - 0.055;
            break;
        case "Rec709full":
            R = (sR < 0.018) ? 4.5 * sR : 1.099 * Math.pow(sR, 0.45) - 0.099;
            G = (sG < 0.018) ? 4.5 * sG : 1.099 * Math.pow(sG, 0.45) - 0.099;
            B = (sB < 0.018) ? 4.5 * sB : 1.099 * Math.pow(sB, 0.45) - 0.099;
            break;
        case "Rec709normative":
            R = (sR < 0.018) ? 4.5 * sR : 1.099 * Math.pow(sR, 0.45) - 0.099;
            G = (sG < 0.018) ? 4.5 * sG : 1.099 * Math.pow(sG, 0.45) - 0.099;
            B = (sB < 0.018) ? 4.5 * sB : 1.099 * Math.pow(sB, 0.45) - 0.099;
            break;
        case "Adobe RGB (1998)":
            gamma = 2.19921875;
            R = Math.pow(sR, 1.0 / gamma);
            G = Math.pow(sG, 1.0 / gamma);
            B = Math.pow(sB, 1.0 / gamma);
            break;
        case "Display P3":
            gamma = 2.4;
            R = (sR <= 0.0031308) ? 12.92 * sR : 1.055 * Math.pow(sR, 1.0 / gamma) - 0.055;
            G = (sG <= 0.0031308) ? 12.92 * sG : 1.055 * Math.pow(sG, 1.0 / gamma) - 0.055;
            B = (sB <= 0.0031308) ? 12.92 * sB : 1.055 * Math.pow(sB, 1.0 / gamma) - 0.055;
            break;
        case "ECIv2":
            gamma = 3.0;
            R = (sR < 0.008856) ? 9.033 * sR : 1.16 * Math.pow(sR, 1.0 / gamma) - 0.16;
            G = (sG < 0.008856) ? 9.033 * sG : 1.16 * Math.pow(sG, 1.0 / gamma) - 0.16;
            B = (sB < 0.008856) ? 9.033 * sB : 1.16 * Math.pow(sB, 1.0 / gamma) - 0.16;
            break;
        case "ProPhoto":
            gamma = 1.8;
            R = (sR < 0.001953) ? 16.00 * sR : Math.pow(sR, 1.0 / gamma);
            G = (sG < 0.001953) ? 16.00 * sG : Math.pow(sG, 1.0 / gamma);
            B = (sB < 0.001953) ? 16.00 * sB : Math.pow(sB, 1.0 / gamma);
            break;
        default:
            console.log("Nieobsługiwana przestrzeń kolorów.");
    }
    return { R, G, B };
}

export default OETF;
