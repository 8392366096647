import React from 'react';
import { useTheme } from './ThemeContext';
import { useTranslation } from 'react-i18next';
import darkmodeIcon from "../assets/darkmode-icon.svg"
import lightmodeIcon from "../assets/lightmode-icon.svg"

const ThemeToggle = () => {
    const { theme, toggleTheme } = useTheme();
    const { t } = useTranslation();

    return (
        <button
            onClick={toggleTheme}
            className={`theme-toggle-button ${theme === "dark" ? "theme-toggle-dark" : "theme-toggle-light"}`}
        >
            {theme === "dark" ? (
                <>
                    <img
                        src={darkmodeIcon}
                        alt="Dark Mode"
                        style={{ marginRight: "8px" }}
                    />
                    <span className='theme-toggle-text'>{t("LightmodeSwitch")}</span>
                </>
            ) : (
                <>
                    <span className='theme-toggle-text'>{t("DarkmodeSwitch")}</span>
                    <img
                        src={lightmodeIcon}
                        alt="Light Mode"
                        style={{ marginLeft: "8px" }}
                    />
                </>
            )}
        </button>

    );
};

export default ThemeToggle;