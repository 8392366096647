import React from 'react';
import CalculatorDeltaE from './math/CalculatorDeltaE';
import '../styles/DeltaE.css'
import iconFiles from "../assets/Files.svg";
import { useTranslation } from 'react-i18next';

const DeltaEResults = (lab1, lab2) => {
  const { t } = useTranslation();  
  const { R8bit: r1, G8bit: g1, B8bit: b1 } = lab1;
  const { R8bit: r2, G8bit: g2, B8bit: b2 } = lab2;

  const DeltaE = CalculatorDeltaE(lab1, lab2);

  const notes = t('notes')
  const difference = t('difference')

  const columns = [
    { title: "Standard", key: "Standard", isHtml: true },
    { title: notes, key: notes, isHtml: true },
    { title: difference, key: difference, isBold: true }
  ];
  const data = [
    {
      Standard: "ΔE 1976 <br/>ISO/CIE 11664-4",
      [notes]: t('euklides'),
      [difference]: DeltaE.DeltaE76
    },{
      Standard: "ΔE CIELUV <br/>CIE 15:2004",
      [notes]: t('cieluv'),
      [difference]: DeltaE.DeltaCIELUV
    },
    {
      Standard: "ΔE CMC 1981<br/>CIE 15:2004, ASTM D2244−15",
      [notes]: "<i>l</i> = 1, <i>c</i> = 1",
      [difference]: DeltaE.CMC
    },
    {
      Standard: "ΔE 1994 <br/>CIE 15:2004, ASTM D2244−15 ",
      [notes]: "Textiles <br/><i>k</i><sub>L</sub> = 2, <i>k</i><sub>1</sub> = 0.048, <i>k</i><sub>2</sub> = 0.014",
      [difference]: DeltaE.DeltaE94Textile
    },
    {
      Standard: "ΔE 1994 <br/>CIE 15:2004, ASTM D2244−15",
      [notes]: "Graphic Arts <br/><i>k</i><sub>L</sub> = 1, <i>k</i><sub>1</sub> = 0.045, <i>k</i><sub>2</sub> = 0.015",
      [difference]: DeltaE.DeltaE94
    },
    {
      Standard: "ΔE DIN 99 <br/>DIN 6176:2001-03",
      [notes]: t('DeltaEDin99') + "<br/>Reference conditions, <i>k</i><sub>E</sub> = 1, <i>k</i><sub>CH</sub> = 1",
      [difference]: DeltaE.DeltaEDin99
    },
    {
      Standard: "ΔE DIN 99 <br/>DIN 6176:2001-03",
      [notes]: t('DeltaEDin99') + "<br/>Textiles, <i>k</i><sub>E</sub> = 2, <i>k</i><sub>CH</sub> = 0.5",
      [difference]: DeltaE.DeltaEDin99Tex
    },
    {
      Standard: "ΔE 2000 <br/>ISO/CIE-11664-6",
      [notes]: "Textiles <br/><i>k</i><sub>L</sub> = 2, <i>k</i><sub>C</sub> = 1, <i>k</i><sub>H</sub> = 1",
      [difference]: DeltaE.DeltaE00Tex
    },
    {
      Standard: "ΔE 2000 <br/>ISO/CIE-11664-6",
      [notes]: "Reference conditions <br/><i>k</i><sub>L</sub> = 1, <i>k</i><sub>C</sub> = 1, <i>k</i><sub>H</sub> = 1",
      [difference]: DeltaE.DeltaE00
    }
  ];
  const lastRowIndex = data.length - 1;
  return (
    <div className="delta-container" >
      <TableDelta data={data} columns={columns} lastRowIndex={lastRowIndex} handleCellClick={(value) => navigator.clipboard.writeText(value)} />
      <div className='delta-colors'>
        <div>
          {t('color')} 1
          <div className='deltacolor' style={{ backgroundColor: `rgb(${r1}, ${g1}, ${b1})` }} />
        </div>
        <div>
          {t('color')} 2
          <div className='deltacolor' style={{ backgroundColor: `rgb(${r2}, ${g2}, ${b2})` }} />
        </div>
      </div>
    </div>
    );
};
export default DeltaEResults;


const TableDelta = ({ data, columns, lastRowIndex, handleCellClick }) => {
  return (
    <table className="conversion-table" style={{ maxWidth: 750 }}>
      <thead>
        <tr>
          {columns.map(column => (
            <th
              key={column.title}
              className="tableHeaderCell"
            >
              {column.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {columns.map((column, colIndex) => (
              <td
                key={column.title}
                style={{
                  padding: '5px',
                  paddingLeft: '15px',
                  background: '#fff',
                  fontWeight: rowIndex === lastRowIndex ? 'bold' : '400',
                  fontSize: colIndex === columns.length - 1 ? 16 : 14,
                  cursor: colIndex === columns.length - 1 ? 'pointer' : 'auto',
                }}
                onClick={colIndex === columns.length - 1 ? () => handleCellClick(row[column.key]) : undefined}
              >
                {column.isHtml ? (
                  <span dangerouslySetInnerHTML={{ __html: row[column.key] }} />
                ) : (
                  row[column.key]
                )}
                {colIndex === columns.length - 1 && (
                  <div
                    style={{
                      float: "right",
                      verticalAlign: "middle",
                      display: "block",
                    }}
                  >
                    <img src={iconFiles} alt="copy"
                      style={{
                        marginTop: "-3px",
                        width: "18px",
                        opacity: "0.4",
                        verticalAlign: "middle",
                      }} />
                  </div>
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
