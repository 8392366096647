export function ParseMultipleLAB(input) {
  const result = input.match(/([+-]?([0-9]*([.,]))?[0-9]+)/gm);

  if (result === null) {
    return [];
  }

  const labValues = result.map((x) => parseFloat(x.replace(",", ".")));

  const groupedLabValues = [];

  for (let i = 0; i < labValues.length; i += 3) {
    groupedLabValues.push(labValues.slice(i, i + 3));
  }

  return groupedLabValues;
}
